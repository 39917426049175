import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Info() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Alert severity="info">
				<AlertTitle><b>Σημαντικό</b></AlertTitle>	
				Δεν υπάρχουν διαθέσιμα προϊόντα σε αυτή τη σελίδα!
			</Alert>
		</div>
	);
}