import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Info from '../components/info'


export const query = graphql`
  query AnodizedDoorEnclosuresQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      address
      phone
      mobile
      email
      keywords
      logo {
        asset {
          url
        }
      }
    }
    products: allSanityProduct(
      sort: {fields: [publishedAt], order: DESC}
      filter: { slug: {current: {ne: null}}, category: {slug: {current: {in: ["doors", "enclosures"]}}}, material: {slug: {current: {eq: "anodized"}}}, publishedAt: {ne: null} }
    ) {
      edges {
        node {
          id
          photo {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          publishedAt
          title
          slug {
            current
          }
        }
      }
    }
  }
`

const AnodizedDoorEnclosuresPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const productNodes = (data || {}).products
    ? mapEdgesToNodes(data.products)
    .filter(filterOutDocsWithoutSlugs)
    .filter(filterOutDocsPublishedInTheFuture)
    : []


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout logo={site.logo.asset.url} address={site.address} telephone={site.phone} mobile={site.mobile} email={site.email}>
      <SEO title={site.title} keywords={site.keywords} />
      <Container>
        {productNodes.length > 0 && (
          <ProjectPreviewGrid
            title='Πορτες / Περιφραξεις Ανοδιωσης'
            nodes={productNodes}
          />
        )}
        {
          productNodes.length == 0 && (
            <>
              <ProjectPreviewGrid title='Πορτες / Περιφραξεις Ανοδιωσης'/>
              <Info />
            </>
          )
        }
      </Container>
    </Layout>
  )
}

export default AnodizedDoorEnclosuresPage
